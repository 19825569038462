footer {
  padding-bottom: 30px;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: $white;
    margin-bottom: 10px;
  }
  .copyright {
    margin-top: 10px;
  }

  .list-unstyled {
    a {
      color: $white;
      &:hover, &:active {
        color: $orange;
      }
    }
  }

}
