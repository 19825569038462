.cart-add-button {
  transition: 0.5s;
  border: none;
  border-radius: 3px;
  background: $orange;
  color: $white;
  display: inline-block;
  padding: 5px 25px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  @include mobile {
    margin-top: 10px;
  }
}

.product-thumb {
  border: none;
  margin-bottom: 20px;
  border: 1px solid $white;
  overflow: auto;
  padding: 10px;
  background: $white;

  &:hover {
    border: 1px solid $orange;
    transition: 1s;
  }

  .caption {
    padding: 0 20px;
    max-height: 110px;
    min-height: 110px;
    overflow: hidden;
  }

  .image {
    text-align: center;

    a {
      display: block;

      img {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .price {
    font-weight: 600;
    font-size: 18px;
    height: 40px;
  }

  .list_product_title {
    a {
      color: $black;
      height: 40px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .sku {
    position: absolute;
    background: $blue;
    padding: 5px;
    color: $white;
    top: 18px;
    z-index: 99;
    @include mobile {
      top: 30px;
    }
  }

  .pay_info {
    height: 40px;
    @include mobile {
      height: auto;
    }
  }

  @include mobile {
    max-width: 90%;
    margin: 20px auto 0 auto;
    padding-bottom: 20px;
  }
}

.product-list {
  .pay_info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: right;
    height: auto;

    .col-md-6 {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .image {
    max-width: 140px;
  }

  .product-thumb {
    .caption {
      min-height: auto;
      margin-left: 160px;
    }
  }
}


.stock_status {
  padding: 3px;
  background: #2db05a;
  color: white;
  display: inline-block;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: $orange;
  border-color: $orange;
  color: $white;
}

.pagination {
  li {
    a {
      color: black;
    }
  }
}
