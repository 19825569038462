#product-category,
#product-search,
#product-manufacturer {

  .content-top {
    padding: 10px;
    background: $white;
    margin-bottom: 30px;

    h1 {
      margin-top: 0;
    }
  }

  #input-sort-button {
    background: $white;
  }

  #input-limit-button {
    background: $white;
  }

  .text-sort {
    top: 3px;
    position: relative;
  }

  .categories {
    &_subcat {
      margin-bottom: 30px;
      background: $white;
      padding: 30px;

      .img {
        min-height: 155px;
        display: block;
        text-align: center;

        img {
          max-height: 155px;
          width: auto;
        }
      }

      .cat_title {
        color: $black;
        font-size: 14px;
        display: block;
        font-width: bold;
        text-align: center;

        &:hover {
          color: $orange;
        }
      }
    }

    &_sort {
      margin-bottom: 30px;
      background: $white;
      padding: 10px;
    }
  }

  .category_description {
    display: inline-block;
    width: 100%;
    background: $white;
    padding: 10px;
    padding-top: 30px;
    margin-top: 30px;
    font-size: 14px;
  }

  #list-view.btn,
  #grid-view.btn {
    border: none;
    background: none;
    box-shadow: none;
  }
}
