#quickcheckoutconfirm {

  #button-login {
    padding: 7px;
  }

  .btn.btn-danger {
    background: none;
    border: none;
    box-shadow: none;
    color: $black;

    i {
      text-shadow: none;
    }

    &:hover {
      color: $orange;
    }
  }
}


#cart {
  .text-left {
    a {
      color: $black;
    }
  }

  .btn.btn-danger {
    background: none;
    border: none;
    box-shadow: none;
    color: $black;

    i {
      text-shadow: none;
    }

    &:hover {
      color: $orange;
    }
  }
}

#checkout-cart {
  .table-responsive {
    .btn {
      padding: 7px;
    }

    .btn-danger {
      background: none;
      border: none;
      box-shadow: none;

      i {
        color: $black;
      }
    }
  }
}
