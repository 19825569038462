.news-list {
  margin-bottom: 20px;
  .title {
    a {
      font-size: 16px;
      text-transform: uppercase;
      color: black;
    }
  }

  .more {
    a {
      color: $orange;
    }
  }

  .added, .viewed {
    display: none;
  }

}
