.megamenu {
  background: $white;

  .menu {
    li {
      margin-bottom: 5px;
      margin-left: 10px;

      a.main-menu {
        color: $gray;
        font-size: 16px;

        &:before {
          display: none;
        }

        &:hover {
          color: $orange;
        }
      }
    }
  }
}

.module-cats {

  .category-header {
    background: $orange;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    height: 42px;
    color: $white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
  }

  .mebel_menu {
    ul {
      padding: 0;

      li {
        list-style: none;
        background: #fff;
        border-bottom: 1px solid $light_gray;
        position: relative;
        cursor: pointer;

        a {
          padding: 10px 15px;
          display: inline-block;
          color: #888;
          font-size: 14px;

          &:hover {
            color: $orange;
          }
        }

        i {
          position: absolute;
          right: 0;
          padding: 14px;
        }

        ul {
          li {
            background: #efefef;
            padding-left: 20px;

            &.active {
              a {
                color: $orange;
              }
            }

            a {
              color: #888;
            }
          }
        }

        &.active {
          background: #ececec;
        }
      }
    }
  }
}

.vertical #menuHeading {
  margin-top: 20px;
}

.container-megamenu {
  .navbar-header {
    @include mobile {
      background: $orange;

      .icon-bar {
        color: $white;
        background-color: $white;
      }
    }
  }
}

.responsive ul.megamenu > li.click::before, .responsive ul.megamenu > li.hover::before {
  color: $orange;
}

.responsive ul.megamenu > li.active .close-menu::before {
  color: $orange;
}

.product_nav .vertical-wrapper {
  @include mobile {
    &.so-vertical-active {
      display: block;
      position: fixed;
    }
  }
}


.megamenu-pattern {
  .item-vertical {
    &:hover {
      background: #f5f5f5;
    }
  }

}
