#information-information {
    #content {
      background: $white;
      padding: 10px 20px;
      p {
        font-size: 16px;
        line-height: 25px;
      }
    }
}


#information-contact {
  .worktime__list {
    padding-left: 0;
    list-style: none;
  }
  .panel-body {
    a {
      color: $black;
      &:hover, &:active {
        color: $orange;
      }
    }
  }
}
