#common-home {
  .swiper-viewport {
    .swiper-button-next {
      right: 25px;
    }

    .swiper-button-prev {
      left: 25px;
    }
  }
}
