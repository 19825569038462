.alert.alert-success {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  border-radius: 0;
  border: none;
  font-size: 14px;
  color: $black;
  background-color: $orange;
  a {
    font-weight: bold;
    text-shadow: none;
    color: $white;
  }
  .close {
    color: $black;
    font-size: 25px;
    opacity: 0.6;
    text-shadow: none;
  }
}

.padding0 {
  padding: 0;
}
iframe {
  width: 100%;
}
.h30 {
  height: 30px;
}

.strong {
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
