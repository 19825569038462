nav#top {
  padding-top: 10px;
  background: $nav_bg;
  margin-bottom: 0;
  min-height: 40px;
  .info-menu {
    ul {
      margin-bottom: 0;
    }

    &.pull-left {
      ul.list-inline {
        @include mobile {
          li {
            margin-bottom: 10px;
          }
        }
      }
    }

    ul.list-inline {
      @include mobile {
        display: none;
        position: absolute;
        z-index: 999;
        top: 42px;
        left: 25px;
        background: $gray;
      }

      li {
        @include mobile {
          display: block;
        }
      }
    }

    a {
      font-size: 14px;
      color: $white;

      &:hover {
        color: $orange;
      }
    }

    .burger {
      display: none;
      cursor: pointer;

      i {
        font-size: 30px;
        color: white;
      }

      @include mobile {
        display: block;
      }
    }

  }

  #top-links {
    padding-top: 0;

    ul {
      margin-bottom: 0;

      a,
      span {
        font-size: 14px;
        color: $white;

        &:hover {
          color: $orange;
        }
      }

      li.open {
        & > a {
          background: transparent;
          border: none;
          color: $orange;
          text-decoration: none;

          &:focus, &:hover {
            background: transparent;
            border: none;
            color: $orange;
            text-decoration: none;
          }
        }

        ul {
          li {
            a {
              color: $orange;

              &:hover {
                background: $gray;
                color: $white;
              }
            }
          }
        }
      }
    }
  }

}

header {
  background: $white;
  padding: 15px;

  #logo {
    @include mobile {
      text-align: center;
      a {
        display: inline-block;
      }
    }
  }

  .btn_x {
    i.fa.fa-shopping-cart {
      color: $white;
      width: 64px;
      height: 60px;
      background: $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 31px;
      position: relative;
      z-index: 5;
    }
  }

  .btn-group.open .dropdown-toggle {
    box-shadow: none;
  }

  .cart__total-cost {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $light_gray;
    padding-top: 5px;
    margin-top: 5px;
    color: $gray;
    font-weight: bold;
    font-size: 15px;
  }

  .cart__total-products {
    width: 100%;
    display: flex;
    justify-content: center;
    color: $orange;
  }

  ul.contacts-top {
    li {
      list-style: none;

      a {
        color: $gray;
        font-weight: bold;
        font-size: 16px;

        &:hover {
          color: $orange;
        }
      }

      img {
        max-width: 20px;
        margin-right: 10px;
      }
    }

    @include mobile {
      padding-left: 0;
      text-align: center;
    }
  }

  ul.worktime__list {
    padding-left: 0;

    li {
      list-style: none;
      font-size: 16px;

      span {
        font-weight: bold;
      }
    }

    @include mobile {
      display: none;
    }
  }

  .go_to {
    &-cart {
      background: $light_gray;
      padding: 13px 17px;
      font-weight: bold;
      color: $gray;
      border-radius: 3px;
      font-size: 14px;
      margin-right: 10px;

      &:hover {
        opacity: .7;
      }
      @include mobile {
        padding: 5px;
        margin-right: 0;
      }
    }

    &-checkout {
      background: $orange;
      padding: 13px 17px;
      font-weight: bold;
      color: $white;
      border-radius: 3px;
      font-size: 14px;

      &:hover {
        opacity: .7;
      }
      @include mobile {
        padding: 5px;
        display: inline-block;
        margin-top: 15px;
      }
    }
  }

  #cart {
    cursor: pointer;

    .dropdown-menu {
      background: $white;
      border: 1px solid $orange;
      padding: 10px;
    }
  }

  .table-striped > tbody > tr:nth-of-type(2n+1) {
    background: none;
    border-bottom: 2px dotted #ececec;
  }

  table.table {
    tbody {
      tr {
        td {
          border-top: none;
        }
      }
    }
  }

  .clock {
    position: absolute;
    left: -20px;
    top: 15px;

    i {
      font-size: 31px;
    }

    @include mobile {
      display: none;
    }
  }
}
