#product-product {
  .breadcrumb {
    margin-top: 10px;
  }

  .alert-success {
    text-align: center;
    color: $white;
  }

  .radio-toolbar {
    display: inline-block;

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      display: inline-block;
      padding: 5px 15px;
      font-family: sans-serif, Arial;
      font-size: 12px;
      border: 1px solid $gray_c;
      border-radius: 4px;
    }
  }

  .radio-toolbar input[type="radio"]:checked + label {
    border-color: $orange;
  }

  .product-body {
    margin-top: 30px;
    background: $white;
    padding: 10px;

    .nav-tabs {
      li {
        a {
          color: $orange;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    .list_haracters {
      .row_x {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5px;
      }

      .row_x:nth-child(2n) {
        background: $light_gray;
      }
    }
  }

  .shipping_info {
    padding: 20px;
    background: $white;
    margin-top: 30px;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;

      .col-md-6 {
        &:nth-child(1) {
          margin-bottom: 30px;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
        }

        div {
          display: block;
          margin-top: 10px;
        }

        img {
          display: block;
        }
      }
    }
  }

  .swiper-slide {
    text-align: center;
    border: 1px solid transparent;
    margin: 3px;
  }

  .swiper-slide-thumb-active {
    border: 1px solid $gray_c;
  }

}

.product_nav {
  .vertical-wrapper {
    display: none;
    position: absolute;
    z-index: 99;
    box-shadow: 0 3px 12px -1px;
    width: 89%;
  }

  #menuHeading {
    cursor: pointer;
  }

  .container-megamenu.vertical {
    &:hover {
      .vertical-wrapper {
        display: block;
      }
    }
  }
}

.product-head {

  .title {
    background: $white;
    padding: 10px;
    margin-bottom: 10px;

    h1 {
      margin: 0;
      display: inline-block;
      font-size: 22px;
    }
  }

  .slider-thumbs {
    background: $white;
    padding: 10px;

    .slider-pr-for {
      text-align: center;
    }

    .slider-pr-nav {
      img {
        max-width: 125px;
        height: auto;
      }
    }
  }

  .price_info {
    text-align: right;
    padding: 10px;
    background: $white;

    .sku-model {
      .text_model {
        font-weight: bold;
        font-size: 16px;
        margin-right: 5px;
        color: $black;
      }

      .model {
        font-size: 14px;
        color: $black;
      }
    }

    .price-block {
      margin: 10px 0;

      .price {
        display: inline-block;
        color: $orange;
        font-size: 30px;
      }

      span {
        color: $gray_c;
        text-decoration: line-through;
        font-size: 24px;
      }
    }

    .btns-add {
      margin-top: 20px;
      width: 100%;

      .btn-def {
        background: none;
        border: none;

        i {
          font-size: 27px;
          color: $gray_c;
        }
      }

      #button-cart {
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

.products_related {
  margin-top: 30px;
  display: inline-block;
  width: 100%;

  .title {
    font-size: 22px;
    color: $black;
    text-align: center;
    margin-bottom: 20px;
  }
}
