#search {
  margin-top: 20px;
  #d_ajax_search_results {
    position: absolute;
    z-index: 999;
    top: 45px;
    left: 20px;
    .result_block {
      .pull-right.block-text.text-left {
        display: none;
      }
    }
    #result_block {
      a {
        background: $white;
        border-bottom: 1px solid $light_gray;
        padding: 10px;
        color: $black;
      }
      .where-find {
        display: none;
      }
    }

    .no-results {
      position: absolute;
      top: -29px;
      z-index: 9;
      right: -29px;
      display: none;
    }
    .all_results {
      //display: inline-block;
      width: 100%;
      margin: 15px;
      display: none;
    }

  }

}
