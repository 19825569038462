body {
  background: $body_bg;
}

.padding0 {
  padding: $p-x0;
}

.btn-primary, .btn-warning, .btn-warning:hover, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled], .btn-primary.focus, .btn-primary:focus {
  text-shadow: none;
  background: $orange;
  color: $white;
}

.btn {
  padding: 10px;
  font-weight: 900;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 #fff3, 0 1px 2px #0000000d;
}

#search {
  margin: 30px 0 20px 0;

  input {
    line-height: 1.42857;
    color: $gray;
    background-color: $white;
    border: 1px solid $light_gray;
    border-radius: 0;
  }

  button {
    width: 90px;
    height: 37px;
    border: none;
    text-shadow: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: $orange;
    color: $white;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
}

#menuHeading {
  border: 1px solid $light_gray;
  border-bottom: none;
  background: $orange;
  color: $white;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;

  .megamenuToogle-wrapper {
    background: $orange;

    .container > div {
      margin: 2px 9px 0 0;
    }
  }
}

.module_title {
  font-size: 23px;
  text-align: center;
  color: $black;
  margin: 10px 0 20px;
  font-weight: bold;
  text-transform: uppercase;
}


.box-heading {
  background: $orange;
  font-size: 18px;
  font-weight: 300;
  box-shadow: none;
  text-shadow: none;
  color: white;
}
