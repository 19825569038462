@charset "UTF-8";

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  max-width: 100%;
  height: auto;
}

input,
textarea,
button {
  font-family: inherit;
}

/* responsive */

.alert.alert-success {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  border-radius: 0;
  border: none;
  font-size: 14px;
  color: #000;
  background-color: #ef532b;
}

.alert.alert-success a {
  font-weight: bold;
  text-shadow: none;
  color: #fff;
}

.alert.alert-success .close {
  color: #000;
  font-size: 25px;
  opacity: 0.6;
  text-shadow: none;
}

.padding0 {
  padding: 0;
}

iframe {
  width: 100%;
}

.h30 {
  height: 30px;
}

.strong {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
  outline: 0;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  overflow: hidden;
}

.slick-dots li,
.slick-dots button {
  text-decoration: none;
}

/* Slider */

/* Icons */

@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.slick-lightbox .slick-loading .slick-list {
  background-color: transparent;
}

.slick-lightbox .slick-prev {
  left: 15px;
}

.slick-lightbox .slick-next {
  right: 15px;
}

.slick-lightbox-hide {
  opacity: 0;
}

.slick-lightbox-hide.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-hide-init {
  position: absolute;
  top: -9999px;
  opacity: 0;
}

.slick-lightbox-hide-init.slick-lightbox-ie {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.slick-lightbox-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slick-lightbox-slick-item {
  text-align: center;
  overflow: hidden;
}

.slick-lightbox-slick-item:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.25em;
}

.slick-caption-bottom .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.slick-caption-dynamic .slick-lightbox-slick-item .slick-lightbox-slick-item .slick-lightbox-slick-caption {
  display: block;
  text-align: center;
}

.slick-lightbox-slick-item-inner {
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-img {
  margin: 0 auto;
  display: block;
  max-width: 90%;
  max-height: 90%;
}

.slick-lightbox-slick-caption {
  margin: 10px 0 0;
  color: white;
}

.slick-lightbox-close {
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
}

.slick-lightbox-close:focus {
  outline: none;
}

.slick-lightbox-close:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.85;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '×';
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  display: none;
  z-index: 1035;
}

.sl-wrapper {
  z-index: 1040;
}

.sl-wrapper button {
  border: 0 none;
  background: transparent;
  font-size: 28px;
  padding: 0;
  cursor: pointer;
}

.sl-wrapper button:hover {
  opacity: 0.7;
}

.sl-wrapper .sl-close {
  display: none;
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 10060;
  margin-top: -14px;
  margin-right: -14px;
  height: 44px;
  width: 44px;
  line-height: 44px;
  font-family: Arial, Baskerville, monospace;
  color: #000;
  font-size: 3rem;
}

.sl-wrapper .sl-close:focus {
  outline: none;
}

.sl-wrapper .sl-counter {
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1060;
  color: #000;
  font-size: 1rem;
}

.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}

.sl-wrapper .sl-navigation button {
  position: fixed;
  top: 50%;
  margin-top: -22px;
  height: 44px;
  width: 22px;
  line-height: 44px;
  text-align: center;
  display: block;
  z-index: 10060;
  font-family: Arial, Baskerville, monospace;
  color: #000;
}

.sl-wrapper .sl-navigation button.sl-next {
  right: 5px;
  font-size: 2rem;
}

.sl-wrapper .sl-navigation button.sl-prev {
  left: 5px;
  font-size: 2rem;
}

.sl-wrapper .sl-navigation button:focus {
  outline: none;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    right: 10px;
    font-size: 3rem;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    left: 10px;
    font-size: 3rem;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    right: 20px;
    font-size: 3rem;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    left: 20px;
    font-size: 3rem;
  }
}

.sl-wrapper .sl-image {
  position: fixed;
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
}

.sl-wrapper .sl-image img {
  margin: 0;
  padding: 0;
  display: block;
  border: 0 none;
  width: 100%;
  height: auto;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0 none;
  }
}

.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0 none;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0 none;
  }
}

.sl-wrapper .sl-image .sl-caption {
  display: none;
  padding: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-top {
  bottom: auto;
  top: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}

.sl-wrapper .sl-image .sl-download {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #000;
  z-index: 1060;
}

.sl-spinner {
  display: none;
  border: 5px solid #333;
  border-radius: 40px;
  height: 40px;
  left: 50%;
  margin: -20px 0 0 -20px;
  opacity: 0;
  position: fixed;
  top: 50%;
  width: 40px;
  z-index: 1007;
  -webkit-animation: pulsate 1s ease-out infinite;
  -moz-animation: pulsate 1s ease-out infinite;
  -ms-animation: pulsate 1s ease-out infinite;
  -o-animation: pulsate 1s ease-out infinite;
  animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.sl-transition {
  transition: -moz-transform ease 200ms;
  transition: -ms-transform ease 200ms;
  transition: -o-transform ease 200ms;
  transition: -webkit-transform ease 200ms;
  transition: transform ease 200ms;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-moz-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-o-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@-ms-keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0.0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

.breadcrumb {
  padding: 10px;
  font-style: normal;
  font-size: 13px;
  background: #fff;
  color: #404040;
  margin-top: 30px;
  border-radius: 0;
  border: none;
}

.breadcrumb li:first-of-type:before {
  display: none;
}

.breadcrumb li a {
  font-style: normal;
  font-size: 13px;
  color: #404040;
}

#product-category .content-top,
#product-search .content-top,
#product-manufacturer .content-top {
  padding: 10px;
  background: #fff;
  margin-bottom: 30px;
}

#product-category .content-top h1,
#product-search .content-top h1,
#product-manufacturer .content-top h1 {
  margin-top: 0;
}

#product-category #input-sort-button,
#product-search #input-sort-button,
#product-manufacturer #input-sort-button {
  background: #fff;
}

#product-category #input-limit-button,
#product-search #input-limit-button,
#product-manufacturer #input-limit-button {
  background: #fff;
}

#product-category .text-sort,
#product-search .text-sort,
#product-manufacturer .text-sort {
  top: 3px;
  position: relative;
}

#product-category .categories_subcat,
#product-search .categories_subcat,
#product-manufacturer .categories_subcat {
  margin-bottom: 30px;
  background: #fff;
  padding: 30px;
}

#product-category .categories_subcat .img,
#product-search .categories_subcat .img,
#product-manufacturer .categories_subcat .img {
  min-height: 155px;
  display: block;
  text-align: center;
}

#product-category .categories_subcat .img img,
#product-search .categories_subcat .img img,
#product-manufacturer .categories_subcat .img img {
  max-height: 155px;
  width: auto;
}

#product-category .categories_subcat .cat_title,
#product-search .categories_subcat .cat_title,
#product-manufacturer .categories_subcat .cat_title {
  color: #000;
  font-size: 14px;
  display: block;
  font-width: bold;
  text-align: center;
}

#product-category .categories_subcat .cat_title:hover,
#product-search .categories_subcat .cat_title:hover,
#product-manufacturer .categories_subcat .cat_title:hover {
  color: #ef532b;
}

#product-category .categories_sort,
#product-search .categories_sort,
#product-manufacturer .categories_sort {
  margin-bottom: 30px;
  background: #fff;
  padding: 10px;
}

#product-category .category_description,
#product-search .category_description,
#product-manufacturer .category_description {
  display: inline-block;
  width: 100%;
  background: #fff;
  padding: 10px;
  padding-top: 30px;
  margin-top: 30px;
  font-size: 14px;
}

#product-category #list-view.btn,
#product-category #grid-view.btn,
#product-search #list-view.btn,
#product-search #grid-view.btn,
#product-manufacturer #list-view.btn,
#product-manufacturer #grid-view.btn {
  border: none;
  background: none;
  box-shadow: none;
}

#quickcheckoutconfirm #button-login {
  padding: 7px;
}

#quickcheckoutconfirm .btn.btn-danger {
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
}

#quickcheckoutconfirm .btn.btn-danger i {
  text-shadow: none;
}

#quickcheckoutconfirm .btn.btn-danger:hover {
  color: #ef532b;
}

#cart .text-left a {
  color: #000;
}

#cart .btn.btn-danger {
  background: none;
  border: none;
  box-shadow: none;
  color: #000;
}

#cart .btn.btn-danger i {
  text-shadow: none;
}

#cart .btn.btn-danger:hover {
  color: #ef532b;
}

#checkout-cart .table-responsive .btn {
  padding: 7px;
}

#checkout-cart .table-responsive .btn-danger {
  background: none;
  border: none;
  box-shadow: none;
}

#checkout-cart .table-responsive .btn-danger i {
  color: #000;
}

footer {
  padding-bottom: 30px;
}

footer .title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}

footer .copyright {
  margin-top: 10px;
}

footer .list-unstyled a {
  color: #fff;
}

footer .list-unstyled a:hover,
footer .list-unstyled a:active {
  color: #ef532b;
}

body {
  background: whitesmoke;
}

.padding0 {
  padding: 0;
}

.btn-primary,
.btn-warning,
.btn-warning:hover,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary.focus,
.btn-primary:focus {
  text-shadow: none;
  background: #ef532b;
  color: #fff;
}

.btn {
  padding: 10px;
  font-weight: 900;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 #fff3, 0 1px 2px #0000000d;
}

#search {
  margin: 30px 0 20px 0;
}

#search input {
  line-height: 1.42857;
  color: #404040;
  background-color: #fff;
  border: 1px solid whitesmoke;
  border-radius: 0;
}

#search button {
  width: 90px;
  height: 37px;
  border: none;
  text-shadow: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #ef532b;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

#menuHeading {
  border: 1px solid whitesmoke;
  border-bottom: none;
  background: #ef532b;
  color: #fff;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

#menuHeading .megamenuToogle-wrapper {
  background: #ef532b;
}

#menuHeading .megamenuToogle-wrapper .container > div {
  margin: 2px 9px 0 0;
}

.module_title {
  font-size: 23px;
  text-align: center;
  color: #000;
  margin: 10px 0 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.box-heading {
  background: #ef532b;
  font-size: 18px;
  font-weight: 300;
  box-shadow: none;
  text-shadow: none;
  color: white;
}

nav#top {
  padding-top: 10px;
  background: #404040;
  margin-bottom: 0;
  min-height: 40px;
}

nav#top .info-menu ul {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  nav#top .info-menu.pull-left ul.list-inline li {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  nav#top .info-menu ul.list-inline {
    display: none;
    position: absolute;
    z-index: 999;
    top: 42px;
    left: 25px;
    background: #404040;
  }
}

@media (max-width: 767px) {
  nav#top .info-menu ul.list-inline li {
    display: block;
  }
}

nav#top .info-menu a {
  font-size: 14px;
  color: #fff;
}

nav#top .info-menu a:hover {
  color: #ef532b;
}

nav#top .info-menu .burger {
  display: none;
  cursor: pointer;
}

nav#top .info-menu .burger i {
  font-size: 30px;
  color: white;
}

@media (max-width: 767px) {
  nav#top .info-menu .burger {
    display: block;
  }
}

nav#top #top-links {
  padding-top: 0;
}

nav#top #top-links ul {
  margin-bottom: 0;
}

nav#top #top-links ul a,
nav#top #top-links ul span {
  font-size: 14px;
  color: #fff;
}

nav#top #top-links ul a:hover,
nav#top #top-links ul span:hover {
  color: #ef532b;
}

nav#top #top-links ul li.open > a {
  background: transparent;
  border: none;
  color: #ef532b;
  text-decoration: none;
}

nav#top #top-links ul li.open > a:focus,
nav#top #top-links ul li.open > a:hover {
  background: transparent;
  border: none;
  color: #ef532b;
  text-decoration: none;
}

nav#top #top-links ul li.open ul li a {
  color: #ef532b;
}

nav#top #top-links ul li.open ul li a:hover {
  background: #404040;
  color: #fff;
}

header {
  background: #fff;
  padding: 15px;
}

@media (max-width: 767px) {
  header #logo {
    text-align: center;
  }

  header #logo a {
    display: inline-block;
  }
}

header .btn_x i.fa.fa-shopping-cart {
  color: #fff;
  width: 64px;
  height: 60px;
  background: #ef532b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 31px;
  position: relative;
  z-index: 5;
}

header .btn-group.open .dropdown-toggle {
  box-shadow: none;
}

header .cart__total-cost {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid whitesmoke;
  padding-top: 5px;
  margin-top: 5px;
  color: #404040;
  font-weight: bold;
  font-size: 15px;
}

header .cart__total-products {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #ef532b;
}

header ul.contacts-top li {
  list-style: none;
}

header ul.contacts-top li a {
  color: #404040;
  font-weight: bold;
  font-size: 16px;
}

header ul.contacts-top li a:hover {
  color: #ef532b;
}

header ul.contacts-top li img {
  max-width: 20px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  header ul.contacts-top {
    padding-left: 0;
    text-align: center;
  }
}

header ul.worktime__list {
  padding-left: 0;
}

header ul.worktime__list li {
  list-style: none;
  font-size: 16px;
}

header ul.worktime__list li span {
  font-weight: bold;
}

@media (max-width: 767px) {
  header ul.worktime__list {
    display: none;
  }
}

header .go_to-cart {
  background: whitesmoke;
  padding: 13px 17px;
  font-weight: bold;
  color: #404040;
  border-radius: 3px;
  font-size: 14px;
  margin-right: 10px;
}

header .go_to-cart:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  header .go_to-cart {
    padding: 5px;
    margin-right: 0;
  }
}

header .go_to-checkout {
  background: #ef532b;
  padding: 13px 17px;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
}

header .go_to-checkout:hover {
  opacity: .7;
}

@media (max-width: 767px) {
  header .go_to-checkout {
    padding: 5px;
    display: inline-block;
    margin-top: 15px;
  }
}

header #cart {
  cursor: pointer;
}

header #cart .dropdown-menu {
  background: #fff;
  border: 1px solid #ef532b;
  padding: 10px;
}

header .table-striped > tbody > tr:nth-of-type(2n+1) {
  background: none;
  border-bottom: 2px dotted #ececec;
}

header table.table tbody tr td {
  border-top: none;
}

header .clock {
  position: absolute;
  left: -20px;
  top: 15px;
}

header .clock i {
  font-size: 31px;
}

@media (max-width: 767px) {
  header .clock {
    display: none;
  }
}

#common-home .swiper-viewport .swiper-button-next {
  right: 25px;
}

#common-home .swiper-viewport .swiper-button-prev {
  left: 25px;
}

#information-information #content {
  background: #fff;
  padding: 10px 20px;
}

#information-information #content p {
  font-size: 16px;
  line-height: 25px;
}

#information-contact .worktime__list {
  padding-left: 0;
  list-style: none;
}

#information-contact .panel-body a {
  color: #000;
}

#information-contact .panel-body a:hover,
#information-contact .panel-body a:active {
  color: #ef532b;
}

.news-list {
  margin-bottom: 20px;
}

.news-list .title a {
  font-size: 16px;
  text-transform: uppercase;
  color: black;
}

.news-list .more a {
  color: #ef532b;
}

.news-list .added,
.news-list .viewed {
  display: none;
}

.cart-add-button {
  transition: 0.5s;
  border: none;
  border-radius: 3px;
  background: #ef532b;
  color: #fff;
  display: inline-block;
  padding: 5px 25px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .cart-add-button {
    margin-top: 10px;
  }
}

.product-thumb {
  border: none;
  margin-bottom: 20px;
  border: 1px solid #fff;
  overflow: auto;
  padding: 10px;
  background: #fff;
}

.product-thumb:hover {
  border: 1px solid #ef532b;
  transition: 1s;
}

.product-thumb .caption {
  padding: 0 20px;
  max-height: 110px;
  min-height: 110px;
  overflow: hidden;
}

.product-thumb .image {
  text-align: center;
}

.product-thumb .image a {
  display: block;
}

.product-thumb .image a img:hover {
  opacity: 0.8;
}

.product-thumb .image img {
  margin-left: auto;
  margin-right: auto;
}

.product-thumb .price {
  font-weight: 600;
  font-size: 18px;
  height: 40px;
}

.product-thumb .list_product_title a {
  color: #000;
  height: 40px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: underline;
}

.product-thumb .sku {
  position: absolute;
  background: #3999ff;
  padding: 5px;
  color: #fff;
  top: 18px;
  z-index: 99;
}

@media (max-width: 767px) {
  .product-thumb .sku {
    top: 30px;
  }
}

.product-thumb .pay_info {
  height: 40px;
}

@media (max-width: 767px) {
  .product-thumb .pay_info {
    height: auto;
  }
}

@media (max-width: 767px) {
  .product-thumb {
    max-width: 90%;
    margin: 20px auto 0 auto;
    padding-bottom: 20px;
  }
}

.product-list .pay_info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: right;
  height: auto;
}

.product-list .pay_info .col-md-6 {
  width: 100%;
  margin-bottom: 10px;
}

.product-list .image {
  max-width: 140px;
}

.product-list .product-thumb .caption {
  min-height: auto;
  margin-left: 160px;
}

.stock_status {
  padding: 3px;
  background: #2db05a;
  color: white;
  display: inline-block;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #ef532b;
  border-color: #ef532b;
  color: #fff;
}

.pagination li a {
  color: black;
}

.megamenu {
  background: #fff;
}

.megamenu .menu li {
  margin-bottom: 5px;
  margin-left: 10px;
}

.megamenu .menu li a.main-menu {
  color: #404040;
  font-size: 16px;
}

.megamenu .menu li a.main-menu:before {
  display: none;
}

.megamenu .menu li a.main-menu:hover {
  color: #ef532b;
}

.module-cats .category-header {
  background: #ef532b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  height: 42px;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.module-cats .mebel_menu ul {
  padding: 0;
}

.module-cats .mebel_menu ul li {
  list-style: none;
  background: #fff;
  border-bottom: 1px solid whitesmoke;
  position: relative;
  cursor: pointer;
}

.module-cats .mebel_menu ul li a {
  padding: 10px 15px;
  display: inline-block;
  color: #888;
  font-size: 14px;
}

.module-cats .mebel_menu ul li a:hover {
  color: #ef532b;
}

.module-cats .mebel_menu ul li i {
  position: absolute;
  right: 0;
  padding: 14px;
}

.module-cats .mebel_menu ul li ul li {
  background: #efefef;
  padding-left: 20px;
}

.module-cats .mebel_menu ul li ul li.active a {
  color: #ef532b;
}

.module-cats .mebel_menu ul li ul li a {
  color: #888;
}

.module-cats .mebel_menu ul li.active {
  background: #ececec;
}

.vertical #menuHeading {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .container-megamenu .navbar-header {
    background: #ef532b;
  }

  .container-megamenu .navbar-header .icon-bar {
    color: #fff;
    background-color: #fff;
  }
}

.responsive ul.megamenu > li.click::before,
.responsive ul.megamenu > li.hover::before {
  color: #ef532b;
}

.responsive ul.megamenu > li.active .close-menu::before {
  color: #ef532b;
}

@media (max-width: 767px) {
  .product_nav .vertical-wrapper.so-vertical-active {
    display: block;
    position: fixed;
  }
}

.megamenu-pattern .item-vertical:hover {
  background: #f5f5f5;
}

.html_product {
  background: #fff;
  padding: 20px;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .html_product {
    max-width: 90%;
    margin: 20px auto 0 auto;
  }
}

.special_products,
.lates_products {
  margin-top: 30px;
}

#search {
  margin-top: 20px;
}

#search #d_ajax_search_results {
  position: absolute;
  z-index: 999;
  top: 45px;
  left: 20px;
}

#search #d_ajax_search_results .result_block .pull-right.block-text.text-left {
  display: none;
}

#search #d_ajax_search_results #result_block a {
  background: #fff;
  border-bottom: 1px solid whitesmoke;
  padding: 10px;
  color: #000;
}

#search #d_ajax_search_results #result_block .where-find {
  display: none;
}

#search #d_ajax_search_results .no-results {
  position: absolute;
  top: -29px;
  z-index: 9;
  right: -29px;
  display: none;
}

#search #d_ajax_search_results .all_results {
  width: 100%;
  margin: 15px;
  display: none;
}

#product-product .breadcrumb {
  margin-top: 10px;
}

#product-product .alert-success {
  text-align: center;
  color: #fff;
}

#product-product .radio-toolbar {
  display: inline-block;
}

#product-product .radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

#product-product .radio-toolbar label {
  display: inline-block;
  padding: 5px 15px;
  font-family: sans-serif, Arial;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#product-product .radio-toolbar input[type="radio"]:checked + label {
  border-color: #ef532b;
}

#product-product .product-body {
  margin-top: 30px;
  background: #fff;
  padding: 10px;
}

#product-product .product-body .nav-tabs li a {
  color: #ef532b;
  font-weight: bold;
  font-size: 16px;
}

#product-product .product-body .list_haracters .row_x {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
}

#product-product .product-body .list_haracters .row_x:nth-child(2n) {
  background: whitesmoke;
}

#product-product .shipping_info {
  padding: 20px;
  background: #fff;
  margin-top: 30px;
}

#product-product .shipping_info .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

#product-product .shipping_info .row .col-md-6:nth-child(1) {
  margin-bottom: 30px;
}

#product-product .shipping_info .row .col-md-6:nth-child(2) {
  margin-bottom: 30px;
}

#product-product .shipping_info .row .col-md-6 div {
  display: block;
  margin-top: 10px;
}

#product-product .shipping_info .row .col-md-6 img {
  display: block;
}

#product-product .swiper-slide {
  text-align: center;
  border: 1px solid transparent;
  margin: 3px;
}

#product-product .swiper-slide-thumb-active {
  border: 1px solid #ccc;
}

.product_nav .vertical-wrapper {
  display: none;
  position: absolute;
  z-index: 99;
  box-shadow: 0 3px 12px -1px;
  width: 89%;
}

.product_nav #menuHeading {
  cursor: pointer;
}

.product_nav .container-megamenu.vertical:hover .vertical-wrapper {
  display: block;
}

.product-head .title {
  background: #fff;
  padding: 10px;
  margin-bottom: 10px;
}

.product-head .title h1 {
  margin: 0;
  display: inline-block;
  font-size: 22px;
}

.product-head .slider-thumbs {
  background: #fff;
  padding: 10px;
}

.product-head .slider-thumbs .slider-pr-for {
  text-align: center;
}

.product-head .slider-thumbs .slider-pr-nav img {
  max-width: 125px;
  height: auto;
}

.product-head .price_info {
  text-align: right;
  padding: 10px;
  background: #fff;
}

.product-head .price_info .sku-model .text_model {
  font-weight: bold;
  font-size: 16px;
  margin-right: 5px;
  color: #000;
}

.product-head .price_info .sku-model .model {
  font-size: 14px;
  color: #000;
}

.product-head .price_info .price-block {
  margin: 10px 0;
}

.product-head .price_info .price-block .price {
  display: inline-block;
  color: #ef532b;
  font-size: 30px;
}

.product-head .price_info .price-block span {
  color: #ccc;
  text-decoration: line-through;
  font-size: 24px;
}

.product-head .price_info .btns-add {
  margin-top: 20px;
  width: 100%;
}

.product-head .price_info .btns-add .btn-def {
  background: none;
  border: none;
}

.product-head .price_info .btns-add .btn-def i {
  font-size: 27px;
  color: #ccc;
}

.product-head .price_info .btns-add #button-cart:hover {
  opacity: .7;
}

.products_related {
  margin-top: 30px;
  display: inline-block;
  width: 100%;
}

.products_related .title {
  font-size: 22px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
}

