.breadcrumb {
  padding: 10px;
  font-style: normal;
  font-size: 13px;
  background: $white;
  color: $gray;
  margin-top: 30px;
  border-radius: 0;
  border: none;

  li {
    &:first-of-type {
      &:before {
        display: none;
      }
    }

    a {
      font-style: normal;
      font-size: 13px;
      color: $gray;
    }
  }
}
