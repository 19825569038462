.html_product {
  background: $white;
  padding: 20px;
  margin-top: 30px;
  @include mobile {
    max-width: 90%;
    margin: 20px auto 0 auto;
  }
}

.special_products, .lates_products {
  margin-top: 30px;
}
